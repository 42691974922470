import React from "react"
import styled from "styled-components"
import { InternalLinkButton, ExternalLinkButton } from "./components"
import { externalLinkPath, pagePath } from "../../../constants/link"

const StyledContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  column-gap: 1rem;
  max-width: 1000px;
  width: 100%;
  margin: 25px auto;
`
const { slowbread, soygood, kurashi } = externalLinkPath
const { path } = pagePath.ourPolicyCommitment
export default function KeywordLinkButtons() {
  return (
    <StyledContainer>
      <InternalLinkButton
        to={path + "#food-additives-with-strict-eyes"}
        text="食品添加物への取組み"
      />
      <InternalLinkButton
        to={path + "#low-trans-fatty-acids"}
        text="低トランス脂肪酸への取組み"
      />
      <InternalLinkButton
        to={path + "#face-to-face-deliciousness"}
        text="国内自給率への取組み"
      />
      <ExternalLinkButton href={slowbread.path} text="天然酵母" />
      <ExternalLinkButton href={kurashi.path} text="食物繊維の研究" />
      <ExternalLinkButton href={soygood.path} text="豆乳生おから" />
    </StyledContainer>
  )
}
