import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import styled, { css } from "styled-components"
import { media } from "../../../../../styles/media"

const StyledContainer = styled.div`
  width: 100%;
  height: 150px;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${() =>
    media.sp(css`
      height: 120px;
    `)}
`
export default function BrandVisual({ path, image }) {
  const stripped = path.replaceAll("/", "")
  if (stripped === "apelle" || stripped === "we") {
    return (
      <StyledContainer>
        <a className="productsLink" href={path}>
          <Image fluid={image} imgStyle={{ scale: "170%" }} />
        </a>
      </StyledContainer>
    )
  }
  return (
    <StyledContainer>
      <Link className="productsLink" to={path}>
        <Image fluid={image} />
      </Link>
    </StyledContainer>
  )
}
