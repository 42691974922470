import React from "react"
import styled from "styled-components"



const PageIndex = styled.div(
  ({ theme }) => `
    width:100%;
    margin-bottom:10px;
    h3{
      font-size:1.32em;
      color:${theme.colors.primary.brown};
    }
    h4{
      color:${theme.colors.primary.brown};
      font-size:.8em;
      line-height:1.62em;
    }
  `
)


const IndexPageHeadlines = (props) => {
  return (
    <PageIndex>
      <h3>{props.titleEn}</h3>
      <h4>{props.titleJp}</h4>
    </PageIndex>
  )
}

export default IndexPageHeadlines
