import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import ButtonContainer from "./ButtonContainer"

const StyledLink = styled(Link)`
  width: 100%;
  display: inline-block;
  padding: 16px 24px;
  text-decoration: none;
`

export default function InternalLinkButton({ to = "#", text = "" }) {
  return (
    <ButtonContainer>
      <StyledLink to={to}>{text}</StyledLink>
    </ButtonContainer>
  )
}
