import React from "react"
import styled from "styled-components"
import BrandVisual from "./BrandVisual"
import Logo from "./Logo"
import { ProductListLinkButton, SpecialPageLinkButton } from "../LinkButton"

const StyledFamilyVisual = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  z-index: 5;
`

export default function FeaturedFamily({
  logoImage,
  brandVisualImage,
  productPageUrl,
  specialPageUrl,
}) {
  return (
    <StyledFamilyVisual>
      <Logo image={logoImage} />
      <BrandVisual path={productPageUrl} image={brandVisualImage} />
      <ProductListLinkButton url={productPageUrl} />
      {!!specialPageUrl && <SpecialPageLinkButton url={specialPageUrl} />}
    </StyledFamilyVisual>
    // </StyledContainer>
  )
}
