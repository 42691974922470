import React, { useState } from "react"
import { Element } from "react-scroll"
import Layout from "../components/Layout"

import SEO from "../components/SEO"
import MainVisual from "../components/MainVisual"
import { OurFamilies, NewProducts } from "../features/index-page"
import CommitmentIndex from "../components/CommitmentIndex"
import SpecialContents from "../components/SpecialContents"
import FacebookIframe from "../components/FacebookIframe"
import OuterLinksSection from "../components/OuterLinksSection"
import { FrancoisKeywords } from "../features/index-page/francois-keywords"

const IndexPage = () => {
  const [isFBiconActive, setIsFBiconActive] = useState(null)

  return (
    <Layout>
      <SEO />
      <MainVisual setIsFBiconActive={setIsFBiconActive} />
      <FacebookIframe
        isIconActive={isFBiconActive}
        setIsIconActive={setIsFBiconActive}
      />
      <Element name="francois-keywords">
        <FrancoisKeywords />
      </Element>
      {/* <Element name="ourFamilies"> */}
      <OurFamilies />
      {/* </Element> */}
      <NewProducts />
      <CommitmentIndex />
      <SpecialContents />
      <OuterLinksSection />
    </Layout>
  )
}

export default IndexPage
