import React from "react"
import styled from "styled-components"
import { HiArrowNarrowRight } from "react-icons/hi"
import { Link } from "gatsby"
import { IconContext } from "react-icons"

const ButtonDesign = styled.div`
  display:flex;
  flex-flow:row wrap;
  align-items:center;
  justify-content:flex-end;
  a{
    background:white;
    padding:.62em .62em;
    color:${({ theme }) => theme.colors.primary.brown};
    display:flex;
    flex-flow:row nowrap;
    align-items:center;
    justify-content:flex-end;
    font-size:13px;
    width:160px;
    border-radius:5px;
    box-shadow:3px 3px 0px rgba(36,36,36,0.1);
    transition:.7s;
    &:hover{
      box-shadow:10px 10px 0px rgba(100,100,100,0.3);
    }
  }
  svg {
    margin-left:.32em;
  }
  `



const BrownButton = (props) => {
  return (
    <ButtonDesign>
      <Link to={props.linkUrl}>
        {props.linkText}
        <IconContext.Provider value={{ color: "#663100" }}>
          <HiArrowNarrowRight />
        </IconContext.Provider>
      </Link>
    </ButtonDesign>
  )
}

export default BrownButton
