import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import styled, { css } from "styled-components"
import { media } from "../../../../../styles/media"

const StyledContainer = styled.div`
  width: 100%;
  height: 200px;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export default function BrandVisual({ path, image }) {
  return (
    <StyledContainer>
      <Link className="productsLink" to={path}>
        <Image fluid={image} />
      </Link>
    </StyledContainer>
  )
}
