import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  a {
    width: 100%;
    margin: 10px 0 0;
    text-align: right;
    padding: 0.62em 0.62em;
    border: 2px solid #663100;
    border-radius: 5px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    font-size: 0.8em;
    svg {
      margin-left: 0.32em;
    }
  }
`

export default function LinkButtonContainer({ children }) {
  return <Container>{children}</Container>
}
