import React from "react"
import { IconContext } from "react-icons"
import { HiArrowNarrowRight } from "react-icons/hi"
import styled from "styled-components"
import LinkButtonContainer from "./LinkButtonContainer"

const LinkButton = styled.a`
  background: white;
  color: #663100;
  transition: 0.6s;
  svg {
    transition: 0.6s;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.primary.green};
    color: white;
  }
`

export default function SpecialPageLinkButton({ url }) {
  return (
    <LinkButtonContainer>
      <LinkButton
        className="specialLink"
        target="_blank"
        rel="noopener noreferrer"
        href={url}
      >
        特設ページを見る
        <IconContext.Provider value={{ color: "#E4D8B9", size: "1.2em" }}>
          <HiArrowNarrowRight />
        </IconContext.Provider>
      </LinkButton>
    </LinkButtonContainer>
  )
}
