import React from "react"
import styled from "styled-components"

const StyledContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: stretch;
  background-color: #fff;
  margin: 10px 0;
  font-weight: 500;
  color: #8d8234;
  width: 256px;
  text-align: center;
  :hover {
    background-color: rgba(242, 242, 242, 0.9);
  }
`
export default function ButtonContainer({ children }) {
  return <StyledContainer>{children}</StyledContainer>
}
