import React from "react"
import styled from "styled-components"
import KeywordLinkButtons from "./KeywordLinkButtons"

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.light.brown};
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  padding-block-start: 50px;
  padding-inline: 5%;
`

const StyledHeadlineContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
  h3 {
    font-size: 1.2em;
    color: #8d8234;
    text-align: center;
  }
  h4 {
    font-size: 0.8em;
    line-height: 1.62em;
  }
`

export default function FrancoisKeywords() {
  return (
    <StyledContainer>
      <StyledHeadlineContainer>
        <h3>フランソアのキーワード</h3>
      </StyledHeadlineContainer>
      <KeywordLinkButtons />
    </StyledContainer>
  )
}
