import React from "react"
import Image from "gatsby-image"
import styled, { css } from "styled-components"
import { media } from "../../../../../styles/media"

const StyledLogo = styled(Image)`
  width: 48%;
  z-index: 5;
  ${() =>
    media.sp(css`
      width: 60%;
    `)}
`

export default function Logo({ image }) {
  return <StyledLogo fluid={image} />
}
