import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled, { css } from "styled-components"
import Image from "gatsby-image"
import BrownButton2 from "./BrownButton2"
import { media } from "../styles/media"

const query = graphql`
  {
    strapiIndexPage {
      commitmentTop {
        commitmentTitle
        commitmentUrl
        commitmentSentence
        commitmentVisual {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const Audi = styled.div`
    display:flex;
    flex-flow:column nowrap;
    align-items:flex-end;
    justify-content:center;
    position:relative;
    height:80vh;
    .comBack{
      width:100%;
      height:100%;
      position:absolute;
      z-index:0;
      &:after{
        content:"";
        display:block;
        height:100%;
        width:100%;
        position:absolute;
        top:0;
        right:0;
        background: linear-gradient(36deg, rgba(255,255,255,.5) 0%, rgba(130,130,130,0.6) 79%, rgba(255,255,255,.4) 100%);
        opacity:0;
      }
      .gatsby-image-wrapper{
        width:100%;
        height:100%;
        object-fit:cover;
      }
    }
    .comContents{
      padding:0 5%;
      position:relative;
      color:white;
      h3{
        font-size:2em;
        margin-bottom:.62em;
        text-shadow:0px 0px 2px gray;
      }
      p{
        margin-bottom:50px;
        text-shadow:0px 0px 2px gray;
      }
    }
${() => media.tablet(css`
    height:40vh;
`
)}
${() => media.sp(css`
    height:60vh;
    .comBack{
      &:after{
        opacity:.3;
      }
    .comContents{
      h3{
        font-size:1.32em;
        font-weight:bold;
      }
    }
`
)}
  `

const CommitmentIndex = () => {
  const data = useStaticQuery(query);
  const { strapiIndexPage: panda } = data;
  return (
    <Audi>
      <div className="comBack">
        <Image
          fluid={panda.commitmentTop.commitmentVisual.childImageSharp.fluid}
        />
      </div>
      <div className="comContents">
        <h3>{panda.commitmentTop.commitmentTitle}</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: panda.commitmentTop.commitmentSentence,
          }}
        />
        <BrownButton2
          linkText="詳細を見る"
          linkUrl={panda.commitmentTop.commitmentUrl}
        />
      </div>
    </Audi>
  )
}

export default CommitmentIndex
