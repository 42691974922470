import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import ButtonContainer from "./ButtonContainer"

const StyledLink = styled.a`
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  padding: 16px 24px;
  text-decoration: none;
`

export default function ExternalLinkButton({ href = "", text = "" }) {
  const icon = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "external-link.svg" }) {
        publicURL
      }
    }
  `)
  return (
    <ButtonContainer>
      <StyledLink
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        icon={icon}
      >
        {text}
        <img src={icon.file.publicURL} alt="external-link" width="16px" />
      </StyledLink>
    </ButtonContainer>
  )
}
