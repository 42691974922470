import React from "react"
import styled, { css } from "styled-components"
import { media } from "../../../styles/media"
import { graphql, useStaticQuery } from "gatsby"
import SmallImageWithLogo from "../../../components/SmallImageWithLogo"
import { Link } from "gatsby"
import { BiRightArrowAlt } from "react-icons/bi"
import { IconContext } from "react-icons"
import { Headline } from "../components"
import { ProductList } from "../../product-list"

const ArrowWhite = () => (
  <IconContext.Provider value={{ size: "1em", color: "white" }}>
    <BiRightArrowAlt />
  </IconContext.Provider>
)

const NewProductsWrapper = styled.div`
  padding: 50px 5%;
  background: white;
  .productWrap {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
`

const BottomLinks = styled.div`
  max-width: 800px;
  margin: 50px auto 100px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  a {
    background: ${({ theme }) => theme.colors.primary.brown};
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0.62em 0.62em 0.62em 1.62em;
    border-radius: 5px;
    color: white;
    svg {
      margin-left: 0.62em;
    }
  }
  ${() =>
    media.sp(css`
      align-items: flex-end;
      a {
        margin-bottom: 15px;
      }
    `)}
`

const NewProducts = () => {
  const data = useStaticQuery(query)
  const {
    allStrapiProducts: { nodes: products },
  } = data
  return (
    <NewProductsWrapper>
      <Headline titleEn="New Products" titleJp="今月の新商品" />
      <ProductList products={products} />
      <BottomLinks>
        <Link to="/thismonth-products/">
          新商品一覧
          <ArrowWhite />
        </Link>
        <Link to="/product-search/">
          商品検索
          <ArrowWhite />
        </Link>
      </BottomLinks>
    </NewProductsWrapper>
  )
}

const query = graphql`
  {
    allStrapiProducts(
      filter: {
        tags: { elemMatch: { tag: { eq: "新商品" } } }
        isDisplay: { eq: true }
        newProductOrder: { gt: 0 }
      }
      sort: { order: ASC, fields: [newProductOrder, brand___order] }
      limit: 6
    ) {
      nodes {
        name
        slug
        excerpt
        catchPhrase
        id
        mainImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        brand {
          slug
          defaultLogo {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          subLogo1 {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default NewProducts
