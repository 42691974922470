import React from "react"
import { Link } from "gatsby"
import { IconContext } from "react-icons"
import { HiArrowNarrowRight } from "react-icons/hi"
import styled from "styled-components"
import LinkButtonContainer from "./LinkButtonContainer"

const LinkButton = styled(Link)`
  color: white;
  background: #663100;
  transition: 0.6s;
  overflow: hidden;
  svg {
    transition: 0.6s;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.light.brown};
    color: ${({ theme }) => theme.colors.primary.brown};
    svg {
      transform: translateX(10px);
      opacity: 0;
    }
  }
`

export default function ProductListLinkButton({ url }) {
  return (
    <LinkButtonContainer>
      <LinkButton to={url}>
        商品を見る
        <IconContext.Provider value={{ color: "white", size: "1.2em" }}>
          <HiArrowNarrowRight />
        </IconContext.Provider>
      </LinkButton>
    </LinkButtonContainer>
  )
}
