import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled, { css } from "styled-components"
import { media } from "../styles/media"
import IndexPageHeadlines from "./IndexPageHeadlines"
import Image from "gatsby-image"
import { Link } from "gatsby"

const query = graphql`
  {
    strapiIndexPage {
      specialContents {
        specialTitleJp
        specialTitleEn
        specialContentsComp {
          id
          specialTitle
          specialUrl
          specialLinkOutbound
          specialIcon {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

const Specials = styled.div`
    background:${({ theme }) => theme.colors.light.brown};
    padding:50px 5%;
    .specialLists{
      display:flex;
      flex-flow:row wrap;
      align-items:flex-start;
      justify-content:center;
      padding:30px 0;
      .specialList{
        width:25%;
        margin:0 3%;
        position:relative;
        a{
          position:absolute;
          width:100%;
          height:100%;
          top:0;
          left:0;
        }
        .specialImg{
          width:100%;
          height:auto;
          position:relative;
          span{
            position:absolute;
            right:0;
            bottom:0;
            background:${({ theme }) => theme.colors.primary.brown};
            color:white;
            font-size:11px;
            padding:4px 20px;
          }
          .gatsby-image-wrapper{
            width:100%;
            height:auto;
          }
        }
      }
      h5{
        width:100%;
        display:block;
        text-align:center;
        color:${({ theme }) => theme.colors.primary.brown};
        padding:.62em 0;
      }
    }
${() => media.sp(css`
    .specialLists .specialList{
      width:44%;
    }
    .specialLists{
      justify-content:flex-start;
    }
`
)}
  `

const SpecialContents = () => {
  const data = useStaticQuery(query)
  const { strapiIndexPage: panda } = data
  return (
    <Specials>
      <IndexPageHeadlines
        titleEn={panda.specialContents.specialTitleEn}
        titleJp={panda.specialContents.specialTitleJp}
      />
      <div className="specialLists">
        {panda.specialContents.specialContentsComp.map(dog => (
          <div className="specialList" key={dog.id}>
            <div className="specialImg">
              <Image fluid={dog.specialIcon.childImageSharp.fluid} />
              <span>MORE</span>
            </div>
            <h5>{dog.specialTitle}</h5>
            {dog.specialUrl && <Link to={dog.specialUrl}></Link>}
            {dog.specialLinkOutbound && (
              <a
                href={dog.specialLinkOutbound}
                target="_blank"
                rel="noopener noreferrer"
              />
            )}
          </div>
        ))}
      </div>
    </Specials>
  )
}

export default SpecialContents
