import React, { useMemo } from "react"
import styled, { css } from "styled-components"
import { media } from "../styles/media"
import { AiOutlineDoubleRight } from "react-icons/ai"
import { IconContext } from "react-icons"

const FacebookSection = styled.div`
  width: 500px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  background: white;
  box-shadow: 0px -2px 3px grey;
  position: absolute;
  top: 0;
  right: -550px;
  height: 100vh;
  z-index: 979;
  background: #663100;
  box-shadow: -3px -3px 10px black;
  iframe {
    width: 400px;
    height: 90%;
  }
  animation: ${props => {
    if (props.isIconActive !== null) {
      if (props.isIconActive) {
        return `fb-slide-in .3s linear 0s forwards`
      }
      return `fb-slide-out .3s linear 0s forwards`
    }
  }};

  @keyframes fb-slide-in {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-550px);
    }
  }
  @keyframes fb-slide-out {
    from {
      transform: translateX(-550px);
    }
    to {
      transform: translateX(0);
    }
  }
  ${() =>
    media.tablet(css`
      iframe {
        height: 80%;
      }
    `)}
  ${() =>
    media.sp(css`
      width: 100%;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      iframe {
        width: 95%;
        order: 1;
      }
      svg {
        order: 2;
        transform: scale(0.8, 0.8);
        margin-top: 5px;
        animation: arr 3s linear 0s alternate infinite;
      }
      @keyframes arr {
        0% {
          transform: translateX(0px);
        }
        15% {
          transform: translateX(0px);
        }
        20% {
          transform: translateX(3px);
        }
        25% {
          transform: translateX(-4px);
        }
        30% {
          transform: translateX(5px);
        }
        35% {
          transform: translateX(0px);
        }
        100% {
          transform: translateX(0px);
        }
      }
    `)}
`
const StyledVscChromeClose = styled(AiOutlineDoubleRight)`
  cursor: pointer;
`

const FacebookIframe = ({ isIconActive, setIsIconActive }) => {
  const src =
    "https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ffrancois.co.jp&tabs=timeline&width=400&height=800&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=642193223323472"
  const memoizedIframe = useMemo(() => {
    return (
      <iframe
        src={src}
        style={{ border: "none", overflow: "hidden" }}
        scrolling="no"
        frameBorder="0"
        // allowFullScreen={true}
        allow="fullscreen; autoplay; clipboard-write; encrypted-media; picture-in-picture;"
        title="Francois Facebook"
      />
    )
  }, [])

  return (
    isIconActive && (
      <FacebookSection isIconActive={isIconActive}>
        {/* Close Icon */}
        <IconContext.Provider value={{ size: "50px", color: "#E4D8B9" }}>
          <StyledVscChromeClose onClick={() => setIsIconActive(false)} />
        </IconContext.Provider>
        {/* Render facebook iframe */}
        {memoizedIframe}
      </FacebookSection>
    )
  )
}

export default FacebookIframe
