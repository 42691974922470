import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled, { css } from "styled-components"
import BarleyImage from "./BarleyImage"
import { Family } from "./Family"
import { FeaturedFamily } from "./FeaturedFamily"
import { Headline } from "../../components"
import { media } from "../../../../styles/media"

const query = graphql`
  {
    strapiIndexPage {
      ourFamilies {
        familyTitleEn
        familyTitleJp
        familiesList {
          id
          productPageUrl
          specialPageUrl
          brandLogo {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
          brandVisual {
            childImageSharp {
              fluid(quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

const StyledContainer = styled.div`
  padding: 50px 5%;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.light.brown};
  position: relative;
`

const StyledFeaturedFamilySection = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  column-gap: 20px;
  margin-block-start: 30px;
  ${() =>
    media.sp(css`
      flex-flow: column nowrap;
      margin-block-end: 24px;
      row-gap: 24px;
    `)}
`

const StyledFamilySection = styled.div`
  width: 100%;
  padding: 30px 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  position: relative;

  ${() =>
    media.sp(css`
      padding: 0 0 30px;
    `)}
`

const OurFamilies = () => {
  const {
    strapiIndexPage: {
      ourFamilies: { familyTitleEn, familyTitleJp, familiesList },
    },
  } = useStaticQuery(query)
  return (
    <StyledContainer id="familyAnchor">
      <Headline titleJp={familyTitleJp} titleEn={familyTitleEn} />
      <BarleyImage />
      <StyledFeaturedFamilySection>
        {familiesList.slice(0, 3).map(family => (
          <FeaturedFamily
            key={family.id}
            logoImage={family.brandLogo.childImageSharp.fluid}
            brandVisualImage={family.brandVisual.childImageSharp.fluid}
            productPageUrl={family.productPageUrl}
            specialPageUrl={family.specialPageUrl}
          />
        ))}
      </StyledFeaturedFamilySection>
      <StyledFamilySection>
        {familiesList.slice(3).map(family => {
          if (family.productPageUrl !== "/francois-bread/") {
            return (
              <Family
                key={family.id}
                logoImage={family.brandLogo.childImageSharp.fluid}
                brandVisualImage={family.brandVisual.childImageSharp.fluid}
                productPageUrl={family.productPageUrl}
                specialPageUrl={family.specialPageUrl}
              />
            )
          }
        })}
      </StyledFamilySection>
    </StyledContainer>
  )
}

export default OurFamilies
