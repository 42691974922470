import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import styled, { css } from "styled-components"
import Image from "gatsby-image"
import { media } from "../styles/media"

const StyledWrap = styled.div`
  width: 100%;
  background: #f4f4f4;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  padding: 50px 5%;
  position: relative;
  .linkData {
    width: 200px;
    height: 120px;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    transition: all 0.6s;
    box-shadow: 10px 10px 0px #dadada;
    margin: 0px 10px;
    &:hover {
      box-shadow: 3px 3px 0px #e2e2e2;
      &::after {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.26);
      transition: 0.6s;
    }

    .gatsby-image-wrapper {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: 0.4s;
    }
    h5,
    p {
      color: white;
      position: absolute;
      z-index: 2;
    }
    h5 {
      top: 20px;
    }
    p {
      top: 45px;
      font-size: 0.8em;
    }
    a {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 3;
    }
  }
  ${() =>
    media.sp(css`
      row-gap: 25px;
    `)}
`

const OuterLinksSection = () => {
  const data = useStaticQuery(query)
  return (
    <StyledWrap>
      {data.strapi.linksBeforeFooter.linksList.map((linkData, index) => (
        <div className="linkData" key={index}>
          <Image
            fluid={linkData.linkMedia.childImageSharp.fluid}
            alt="linkMedia"
          />
          <h5>{linkData.linkNameEn}</h5>
          <p
            dangerouslySetInnerHTML={{ __html: linkData.linkNameJp }}
            style={{ textAlign: "center", lineHeight: "1.4" }}
          />
          <a
            href={linkData.linkUrl}
            target="_blank"
            rel="noopener noreferrer"
          ></a>
        </div>
      ))}
    </StyledWrap>
  )
}

const query = graphql`
  {
    strapi: strapiIndexPage {
      linksBeforeFooter {
        linksList {
          id
          linkNameEn
          linkNameJp
          linkUrl
          linkMedia {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

export default OuterLinksSection
