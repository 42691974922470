import React from "react"
import styled, { css } from "styled-components"
import BrandVisual from "./BrandVisual"
import Logo from "./Logo"
import { ProductListLinkButton, SpecialPageLinkButton } from "../LinkButton"
import { media } from "../../../../../styles/media"

const StyledContainer = styled.div`
  width: 23%;
  margin: 20px 1% 20px;
  z-index: 5;
  ${() =>
    media.sp(css`
      width: 48%;
    `)}
`

const StyledFamilyVisual = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`

export default function Family({
  logoImage,
  brandVisualImage,
  productPageUrl,
  specialPageUrl,
}) {
  return (
    <StyledContainer>
      <StyledFamilyVisual>
        <Logo image={logoImage} />
        <BrandVisual path={productPageUrl} image={brandVisualImage} />
      </StyledFamilyVisual>
      <ProductListLinkButton url={productPageUrl} />
      {!!specialPageUrl && <SpecialPageLinkButton url={specialPageUrl} />}
    </StyledContainer>
  )
}
