import React, { useEffect, useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { media } from "../styles/media"
import styled, { css } from "styled-components"
import { Link as ScrollLink } from "react-scroll"
import { BiChevronDown } from "react-icons/bi"
import { FaFacebookSquare } from "react-icons/fa"
import { IconContext } from "react-icons"
import { useInView } from "react-intersection-observer"
import { HeaderOverWrappedContext } from "../hooks/HeaderOverWrappedContext"
import FrancoisLogo from "../images/francoisLogoENwhite2.svg"

const query = graphql`
  {
    strapiIndexPage {
      mainVisual {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

const StyledBackground = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  .gatsby-image-wrapper {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

const StyledMainVisual = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background: transparent;
  .logoImg {
    width: 400px;
  }
  a {
    position: absolute;
    bottom: 15%;
    width: 5em;
    height: 5em;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }
  .downArrow {
    position: absolute;
    bottom: 20%;
    animation: bike 2s linear 0s infinite forwards;
    @keyframes bike {
      0% {
        transform: translateY(0px);
      }
      10% {
        transform: translateY(0px);
      }
      20% {
        transform: translateY(0px);
      }
      30% {
        transform: translateY(5px);
      }
      40% {
        transform: translateY(0px);
      }
      50% {
        transform: translateY(0px);
      }
      60% {
        transform: translateY(15px);
      }
      70% {
        transform: translateY(0px);
      }
      100% {
        transform: translateY(0px);
      }
    }
    &:hover {
      cursor: pointer;
      animation-play-state: paused;
    }
  }
  .facebookIconMordal {
    position: absolute;
    bottom: 15%;
    right: 15%;
    &:hover {
      cursor: pointer;
    }
  }
  ${() =>
    media.sp(css`
      .logoImg {
        width: 60%;
      }
      .downArrow {
        bottom: 25%;
      }
    `)}
`

const MainVisual = ({ setIsFBiconActive }) => {
  const data = useStaticQuery(query)
  const { strapiIndexPage: panda } = data
  const [state, setState] = useContext(HeaderOverWrappedContext)

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.5,
  })

  useEffect(() => {
    setState(state => ({ ...state, isDisplay: !inView }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  return (
    <>
      <StyledMainVisual ref={ref}>
        <img className="logoImg" src={FrancoisLogo} alt="フランソアのロゴ" />
        <ScrollLink
          activeClass="active"
          to="francois-keywords"
          spy={true}
          smooth={true}
          offset={-50}
          duration={500}
        >
          <IconContext.Provider
            value={{ color: "white", size: "3em", className: "downArrow" }}
          >
            <BiChevronDown />
          </IconContext.Provider>
        </ScrollLink>

        <IconContext.Provider
          value={{
            color: "white",
            size: "2.4em",
            className: "facebookIconMordal",
          }}
        >
          <FaFacebookSquare onClick={() => setIsFBiconActive(prev => !prev)} />
        </IconContext.Provider>
        {/* </button> */}
      </StyledMainVisual>
      <StyledBackground>
        <Image fluid={panda.mainVisual.childImageSharp.fluid} />
      </StyledBackground>
    </>
  )
}

export default MainVisual
