import React from "react"
import styled from "styled-components"
import barleySvg from "../../../../images/barley-francois.svg"

const StyledContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.2;
  width: 55%;
  z-index: 1;
  img {
    width: 100%;
  }
`
export default function BarleyImage() {
  return (
    <StyledContainer className="backBarley">
      <img src={barleySvg} alt="" />
    </StyledContainer>
  )
}
